import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <section className="page_404">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 d-flex justify-content-center  ">
          <div className="col-sm-10 col-sm-offset-1  text-center">
            <div className="four_zero_four_bg">
              <h1 className="text-center ">404</h1>
            </div>
            <div className="contant_box_404">
              <h3 className="h2">Look like you're lost</h3>
              <Link to="/"  className="link_404 mid-btn" style={{textDecoration:'none'}}>
                Go to Home
                </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default ErrorPage