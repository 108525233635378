import React from "react";
import { Link } from "react-router-dom";

const TermsCondition = () => {
  return (
    <>
      <div className="privacy-terms" style={{ height: "50vh" }}>
        <div
          className="web-content-heading text-center d-flex fw-bold text-white"
          style={{
            paddingTop: "8rem",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1 className="text-center">Terms and Conditions of ImageMagix</h1>
          <Link to='/' className="btn btn-primary px-3 rounded-4" style={{textDecoration:"none"}}>More About ImageMagix</Link>
        </div>
      </div>

      <div className="container">
      <div className="web-content" style={{marginInline:'5rem',fontSize:'14px',lineHeight:'2rem'}}>
        <div className="mt-5">
          <h4 style={{fontWeight:'bold'}}>Terms and Conditions for ImageMagix</h4>
          <p>
            These terms and conditions ("Terms") govern your access to and use
            of ImageMagix (the "App") provided by NextGen TechEdge Solutions Pvt. Ltd. ("we," "us,"
            or "our"). By downloading, installing, or using the App, you agree
            to be bound by these Terms. If you do not agree with any part of
            these Terms, you may not use the App.
          </p>
        </div>
        <div>
          <h4>Use of the App: </h4>
          
            <p>You must be at least 13 years old to use the App. </p>
            <p>
              You agree to use the App only for purposes that are permitted by
              these Terms and any applicable law, regulation, or generally
              accepted practices or guidelines in the relevant jurisdictions.{" "}
            </p>
            <p>
              You are solely responsible for any content you create, edit,
              share, or publish using the App.{" "}
            </p>
          
        </div>
        <div>
          <h4>Intellectual Property: </h4>
          
            <p>
              The App and its original content, features, and functionality are
              owned by NextGen TechEdge Solutions Pvt. Ltd. and are protected by international
              copyright, trademark, patent, trade secret, and other intellectual
              property or proprietary rights laws.{" "}
            </p>
            <p>
              You may not modify, reproduce, distribute, create derivative
              works, display, or perform any of the content or software of the
              App.{" "}
            </p>
          
        </div>

        <div>
          <h4>Privacy:</h4>
          <p>
            Your use of the App is also subject to our Privacy Policy, which
            explains how we collect, use, and disclose your information. By
            using the App, you consent to our Privacy Policy.{" "}
          </p>
        </div>

        <div>
          <h4>Disclaimer: </h4>
          
            <p>
              The App is provided on an "as-is" and "as available" basis,
              without any warranties, express or implied, including, but not
              limited to, the implied warranties of merchantability, fitness for
              a particular purpose, or non-infringement.{" "}
            </p>
            <p>
              We do not warrant that the functions contained in the App will be
              uninterrupted or error-free, that defects will be corrected, or
              that the App or the server that makes it available are free of
              viruses or other harmful components.{" "}
            </p>
          
        </div>

        <div>
          <h4>Changes to Terms: </h4>
          <p>
            We reserve the right to modify these Terms at any time. All changes
            are effective immediately upon posting. Your continued use of the
            App constitutes your acceptance of the modified Terms.{" "}
          </p>
        </div>

        <div>
          <h4>Contact Us: </h4>
          <p>If you have any questions about these Terms, please contact us.</p>
        </div>
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
