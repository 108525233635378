import '../App.css'
import { useFormik } from "formik";
import { useState,useRef } from 'react';
import { signInSchema } from '../schemas';
import {signInWithEmailAndPassword, deleteUser} from "@firebase/auth";
import { auth } from "../firebase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFirestore, collection, doc, deleteDoc } from 'firebase/firestore';
import { postData, postDataToAWS } from '../api/api'

const initialValues = {
    email:'',
    password:'',
  }

const AccountDelete = () => {

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const errorMessageMap = {
    "auth/invalid-login-credentials":"Invalid login credentials",
    default: 'An unknown error occurred. Please try again later.',
  };
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
    formikHandleSubmit(event);
  };
  const {values, errors, touched, handleBlur, handleChange, handleSubmit: formikHandleSubmit} = useFormik({
    initialValues:initialValues,
    validationSchema:signInSchema,
    onSubmit: async(values,{resetForm}) =>{
      await signInWithEmailAndPassword(auth, values.email, values.password)
      .then((userCredential) => {
        const user = auth.currentUser;
        const firestore = getFirestore();
        const userDocRef = doc(firestore, 'users', user.uid);
        deleteDoc(userDocRef).then(() => {
            deleteUser(user)
            const jsonObject = {};
            jsonObject["first_name"] = user.displayName;
            jsonObject["last_name"] = user.displayName;
            jsonObject["email"] = user.email;
            jsonObject["uuid"] = user.uid;
            try {
              const result = postDataToAWS(jsonObject);
              // console.log('POST request successful:', result);
              // Handle successful response (update state, show success message, etc.)
            } catch (error) {
              // Handle error (show error message, log, etc.)
              // console.error('Error submitting data:', error);
            }
            toast.success("Account deleted successfully.");
            resetForm();
        }).catch((error) => {
            toast.error("There was an error, Please try again later");
        });
      }).catch((er) => {
        const errorMessage = errorMessageMap[er.code] || errorMessageMap.default;
        toast.error(errorMessage);
      });
    }
    })


  return (
    <>
      <section className="container contact">
        <h2 className="h1-responsive font-weight-bold text-center my-4">
          Delete your account
        </h2>

        <div className="row">

          <div className="col-md-12 mb-md-0 mb-5" style={{height: '24rem'}}>
            <form className='contact-form' style={{marginInline:'5rem',padding:'2rem',borderRadius:'10px'}} onSubmit={handleSubmit}>

              <div className="row">
                <div className="col-md-6">
                  <div className="md-form mb-0">
                  <label htmlFor="email" className="">
                    Email
                  </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="form-control"
                      autoComplete="off"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ?<p className="form-error text-danger">{errors.email}</p>:null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="md-form mb-0">
                  <label htmlFor="password" className="">
                    Password
                  </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control"
                      autoComplete="off"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.password && touched.password ?<p className="form-error text-danger">{errors.password}</p>:null}
                  </div>
                </div>
              </div>
            <button type="submit" className="mid-btn mt-3">Delete</button>

            </form>
            <ToastContainer 
              position="top-center"
            />
          </div>
        </div>
      </section>

    </>
  );
};

export default AccountDelete;
