

import HomePage from './components/HomePage';
import './App.css';
import Middle from './components/Middle';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsCondition from './components/TermsCondition';
import {Routes, Route} from 'react-router-dom';
import Contact from './components/Contact';
import About from './components/About';
import BackgroundRm from './components/features/BackgroundRm';
import Colorise from './components/features/Colorise';
import UpScale from './components/features/UpScale';
import Header from './components/Header';
import ScrollToTop from './components/ScrollToTop';
import ErrorPage from './components/ErrorPage';
import AccountDelete from './components/AccountDelete';
import Profile from './components/Profile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChangePassword from './account/ChangePassword';

function App() {
  
  return (
   <>

        <Header/>
        <Routes>
          <Route path='/' element={<><HomePage/><Middle/></>}></Route>
          <Route path='/back-remove' element={ <BackgroundRm/>}/>
          <Route path='/account-delete' element={ <AccountDelete/>}/>
          <Route path='/colorise' element={ <Colorise/>}/>
          <Route path='/upscale' element={ <UpScale/>}/>
          <Route path='/about' element={ <About/>}/>
          <Route path='/privacy' element={ <PrivacyPolicy/>}/>
          <Route path='/terms-condition' element={ <TermsCondition/>}/>
          <Route path='/contact-us' element={ <Contact/>}/>
          <Route path='/profile' element={ <Profile/>}/>
          <Route path='/change-password' element={ <ChangePassword/>}/>
          <Route path='/*' element={ <ErrorPage/>}/>
        </Routes>
        <ScrollToTop/>
      <Footer/>
      <ToastContainer position="top-center" />
      </>
  );
}

export default App;
