import '../App.css'
import { useFormik } from "formik";
import { changePasswordSchema } from "../schemas";
import { useState, useRef, useContext } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../context/usercontext';
import { updatePassword } from "firebase/auth";
import { logout } from "../auth/auth";
import { useNavigate } from "react-router-dom";


const initialValues = {
  newPassword:'',
  confirmPassword:'',
}

const ChangePassword = () => {

  const navigate = useNavigate();
  const [isCaptchaCompleted, setIsCaptchaCompleted] = useState(false);
  const recaptchaRef = useRef(null);
  const { user } = useContext(UserContext);
    
  // for capcha
  function onChange(value) {
    // console.log("Captcha value:", value);
    setIsCaptchaCompleted(true);
  }
  const handleLogout = async () => {
    await logout(navigate); // Call the logout function
    // Optionally, you can perform additional cleanup or redirect logic here
  };
  const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues:initialValues,
    validationSchema:changePasswordSchema,
    onSubmit: async(values,{resetForm}) =>{
        if(isCaptchaCompleted){
            updatePassword(user, values.newPassword).then(() => {
                toast.success("your password is changed successfully");
              }).catch((error) => {
                if (error.code === "auth/requires-recent-login") {
                    toast.error("Please try after re-login.");
                    handleLogout();
                } else if(error.code === "auth/weak-password") {
                    toast.error("Password you entered is week.");
                }else {
                    toast.error("An error occurred while signing up. Please try again later.");
                }
              });
        } else{
            toast.error("You must select re-captcha.");
        }
    }

  })


  return (
    <>
      <section className="container contact">
        <h2 className="h1-responsive font-weight-bold text-center my-4">
          Change Password
        </h2>
        <div className="row">

          <div className="col-md-12 mb-md-0 mb-5">
            <form className='contact-form' style={{marginInline:'5rem',padding:'2rem',borderRadius:'10px'}} onSubmit={handleSubmit}>

              <div className="row">

                <div className="col-md-6">
                  <div className="md-form mb-0">
                  <label htmlFor="password" className="">
                      Password
                    </label>
                    <input
                      type="password"
                      id="newPassword"
                      name="newPassword"
                      className="form-control"
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.newPassword && touched.newPassword ? <p className="form-error text-danger">{errors.newPassword}</p>:null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="md-form mb-0">
                  <label htmlFor="confirmPassword" className="">
                    Confirm Password
                  </label>
                    <input
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      className="form-control"
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.confirmPassword && touched.confirmPassword ?<p className="form-error text-danger">{errors.confirmPassword}</p>:null}
                  </div>
                </div>

              </div>
              <div className="captcha">
                <ReCAPTCHA className='mt-3' ref={recaptchaRef} sitekey="6LcmtTspAAAAAArJWbCQ4dk9cnPDhIGaNdQwJGPq" onChange={onChange}/>
              </div>
              <div className='text-lg-start text-center'>
              <button type="submit" className="btn btn-primary rounded-4 mt-3 w-lg-0 w-50">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </section>

    </>
  );
};

export default ChangePassword;
