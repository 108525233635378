import React, { useContext } from "react";
import logo from "../lgo.png";
import { Link } from "react-router-dom";
import '../App.css';
import AccountSignInUp from "../account/AccountSignInUp";
import { UserContext } from '../context/usercontext';

const Header = () => {
  const { user } = useContext(UserContext);
  
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="logo" style={{ width: "130px", borderRadius: '8px' }} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <div
                  className="nav-link dropdown-toggle rounded-4 float-end float-lg-start"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Tools
                </div>
                <ul className="dropdown-menu bg-grey mt-lg-0 mt-md-5 mt-5 mb-lg-0 mb-2">
                  <li>
                    <Link to='/back-remove' className="dropdown-item">
                      Background Remover
                    </Link>
                  </li>
                  <li>
                    <Link to='/colorise' className="dropdown-item">
                      Colorize
                    </Link>
                  </li>
                  <li>
                    <Link to='/upscale' className="dropdown-item">
                      Upscale
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                {user ? ( // Render the logout button when a user is authenticated
                  <div>
                    <button className="mid-btn btn">
                      <Link style={{ textDecoration: 'none', color: '#fff' }} to='/profile'>Profile</Link>
                    </button>
                  </div>
                ) : (
                  <AccountSignInUp />
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
