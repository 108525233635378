import React, { useContext } from "react";
import { UserContext } from "../context/usercontext";
import { sendEmailVerification } from "@firebase/auth";
import { auth } from "../firebase";
import profile from "../profilepic.png";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logout } from "../auth/auth";
import { useNavigate } from "react-router-dom";
import { Badge } from 'react-bootstrap';
import { getItem } from '../storage/storage';

const Profile = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  console.log("profile:", user);
  const userData = getItem("userData");
  // console.log(userData);
  const handleSendEmail = async () => {
    if (user) {
      try {
        await sendEmailVerification(auth.currentUser);
        toast.success("Verification email sent!");
      } catch (error) {
        console.error("Error sending verification email:", error.message);
        toast.error("there is some error in sending verification email. Please try again.");
      }
    }
  }
  const handleLogout = async () => {
    await logout(navigate); // Call the logout function
    // Optionally, you can perform additional cleanup or redirect logic here
  };

  return (
    <div className="container">
      {user ?
        <div className="row">
          {/* First Card - Profile Info */}
          <div className="col-md-4">
            <div className="card mt-5">
              <div className="card-body text-center">
                <img src={userData ? userData.image_url : profile} alt="profile" style={{ width: '30%', borderRadius: '50%' }} />
                <h3>{userData ? userData.name : 'Loading...'}</h3>
                {user.emailVerified ? (
                  <div>
                    <Badge bg="success">Verified</Badge>
                  </div>
                ) : (
                  <span>
                    Please click{" "}
                    <a href="#" onClick={handleSendEmail}>here</a>{" "}
                    to re-send verification email.
                  </span>
                )}
                
              </div>
            </div>
            <div className="leftbutton">
              <div className="col-md-12 profile-btn text-center">
                <a className="w100 btn btn-primary mt-2" href="/change-password" >Change Password</a>
              </div>
              <div className="col-md-12 profile-btn text-center">
                  <button className="w100 btn btn-primary mt-2" onClick={handleLogout}>Logout</button>
              </div>
            </div>
          </div>

          {/* Second Card - User Details */}
          <div className="col-md-8">
            <div className="card mt-5">
              <div className="card-body">
                <div className="profileHeader">
                  <span>Profile</span>
                </div>
                <table className="table table-bordered" style={{marginBottom: 0}}>
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{userData ? userData.name : 'Loading...'}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{userData ? userData.email : 'Loading...'}</td>
                    </tr>
                    <tr>
                      <th>Provider</th>
                      <td>{userData ? userData.provider : 'Loading...'}</td>
                    </tr>
                    <tr>
                      <th>Logged in from</th>
                      <td>{userData ? userData.device : 'Loading...'}</td>
                    </tr>
                    <tr>
                      <th>Plan Name</th>
                      <td>Free</td>
                    </tr>
                    <tr>
                      <th>Description</th>
                      <td>Free plan includes 10 images per day limit.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </div>
        </div>

        : <p className="text-center mt-5">You are not login. Please login first</p>}
    </div>
  );
};

export default Profile;
