import React from "react";
import homeImage from "../images/homi.png";
import bgromve from "../images/eraser.png";
import upscale from "../images/scale.png";
import colorise from "../images/color-circle.png";
import "react-lazy-load-image-component/src/effects/blur.css";
import { UserContext } from "../context/usercontext";
import { useContext } from "react";

import { Link } from "react-router-dom";

const HomePage = () => {
  const { user } = useContext(UserContext);
  return (
    <div className="app" style={{ background: 'linear-gradient(45deg, #9695df, rgb(241 176 249 / 41%), rgb(243 220 107 / 75%))'}}>

      <div className="home-page container">
        <div className="row">
          <div className="col-lg-7 mt-lg-5 mt-3">
            <div>
              <h1>ImageMagix: A Universal Photo Editing Solution for All</h1>
              <p>
                Transforming snapshots into masterpieces — Imagemix: where photo
                editing meets simplicity for everyone.
              </p>
              <div className="text-lg-start text-center">
                <a href="https://play.google.com/store/apps/details?id=co.imagemagix.imagemagix" className="btn btn-primary rounded-4 px-3" target="_blank" rel="noopener noreferrer">Download app here</a>
              </div>
            </div>

            <div className='tools mt-5'>
              <Link to='/back-remove'><span><img className='tools-img' src={bgromve} alt='bg-rm'/></span>Background Remover</Link>
              <Link to='/colorise'><span><img className='tools-img' src={colorise} alt='colorise'/></span>Colorise</Link>
              <Link to='/upscale'><span><img className='tools-img' src={upscale} alt='upscale'/></span>Upscale</Link>
            </div>
          </div>
          <div className="col-lg-5 my-0 my-lg-0">
            <img src={homeImage} alt="homeimg" style={{ width: "100%" }} />
          </div>
          <div className='tools1'>
            <Link to='/back-remove'><span><img className='tools-img' src={bgromve} alt='bg-rm'/></span>Background Remover</Link>
            <Link to='/colorise'><span><img className='tools-img' src={colorise} alt='colorise'/></span>Colorise</Link>
            <Link to='/upscale'><span><img className='tools-img' src={upscale} alt='upscale'/></span>Upscale</Link>
            </div>
        </div>
      </div>
      {/*<div className="banner-page d-flex align-items-center justify-content-center">



      <div className="category-heading" style={{ marginLeft: "" }}>
        <p style={{fontWeight:'bold'}}>Explore trending functionalities</p>
        <div className="categories d-flex gap-5">
          <Link to='/back-remove'
            className="box d-flex align-items-center justify-content-center gap-1"
            style={{
              padding: "10px",
              borderRadius: "5px",
              background: "linear-gradient(45deg, #08357b, transparent)",
              width: "10rem",
              cursor: "pointer",
              textDecoration:'none'
            }}
          >
            <img
              src={backRemove}
              alt="background-remover"
              style={{ width: "30px" }}
              loading='lazy'
            />
            <p className="text-white" style={{marginBottom:'0px'}}>Background Remover</p>
          </Link>
          <Link to='/colorise'
            className="box d-flex align-items-center justify-content-center gap-1"
            style={{
              padding: "10px",
              borderRadius: "5px",
              background: "linear-gradient(45deg, #08357b, transparent)",
              width: "10rem",
              cursor: "pointer",
              textDecoration:'none'
            }}
          >
            <img src={colorise} alt="colorise" style={{ width: "30px" }} loading='lazy' />
            <p className="text-white" style={{marginBottom:'0px'}}>Colorise</p>
          </Link>
          <Link to='/upscale'
            className="box d-flex align-items-center justify-content-center gap-1"
            style={{
              padding: "10px",
              borderRadius: "5px",
              background: "linear-gradient(45deg, #08357b, transparent)",
              width: "10rem",
              cursor: "pointer",
              textDecoration:'none'
            }}
          >
            <img src={upscale} alt="upscale" style={{ width: "30px" }} loading='lazy' />
            <p className="text-white" style={{marginBottom:'0px'}}>Upscale</p>
          </Link>
        </div>
      </div>
    </div>*/}
    </div>
  );
};

export default HomePage;
