import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth } from "../firebase";

const Forgot = ({handleModalHide}) => {

  const [email, setEmail] = useState('');
  const [emailerr, setEmailErr] = useState('');

  const handleForgot = (e) => {
  e.preventDefault();
  
  const emailInput = document.getElementById("email");

  const email = emailInput.value;
  const emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;


    emailInput.addEventListener('input', () => {
      setEmailErr('');
    });

    var f = 1;

    if (email.trim() === "") {
      setEmailErr("Please enter your email address");
      f = 0;
    }
    if (email.trim() !== "" && !emailReg.test(email)) {
      setEmailErr("Please enter valid email");
      f = 0;
    }

    if(f === 0){
      return false;
    }
    try {
      sendPasswordResetEmail(auth, email);
      emailInput.value = "";
      handleModalHide();
      toast.success("Please check your email");
    }catch (error) {
      toast.error("An error occurred while signing up. Please try again later.");
    }
}

  return (
    <div>

      <form className="forgot-form">
        <div className="mb-2">
          <label htmlFor="exampleInputEmail1" className="form-label text-black">
            Email address
          </label>
          <input type="email" className="form-control shadow-none" id="email" value={email} onChange={()=>setEmail()} />
          <div className="error-message text-danger">{emailerr}</div>
        </div>
        <button type="submit" className="btn mid-btn" style={{width:'100%'}} onClick={(e)=>handleForgot(e)}>
          Send
        </button>
      </form>
     
    </div>
  );
};

export default Forgot;
