// auth.js

import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { removeItem } from "../storage/storage";

export const logout = async (navigate) => {
  try {
    await signOut(auth);
    removeItem("userData");
    // Redirect to the home page after successful logout
    navigate("/");
  } catch (error) {
    console.error("Error signing out:", error);
    // Handle any errors that occur during sign-out
  }
};
