// LoginForm.js
import { createUserWithEmailAndPassword, sendEmailVerification } from '@firebase/auth';
import React, { useState, useContext } from 'react';
import {auth, db } from '../firebase';
import { setDoc, doc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postData } from '../api/api'
import { UserContext } from '../context/usercontext';
import { setItem } from '../storage/storage';

const SignUp = ({ toggleForms, handleModalHide }) => {

const {setUser} = useContext(UserContext);
const [fname,setFName] = useState('');
const [lname,setLName] = useState('');
const [email,setEmail] = useState('');
const [password,setPassword] = useState('');

const [fnameerr,setFNameErr] = useState('');
const [lnameerr,setLNameErr] = useState('');
const [emailerr,setEmailErr] = useState('');
const [passworderr,setPasswordErr] = useState('');

const handleSignUp = async (e) =>{
  e.preventDefault();

  const fnameInput = document.getElementById("fname");
  const lnameInput = document.getElementById("lname");
  const emailInput = document.getElementById("email");
  const passwordInput = document.getElementById("password");

  const fname = fnameInput.value;
  const lname = lnameInput.value;
  const email = emailInput.value;
  const password = passwordInput.value;
  const emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;


  fnameInput.addEventListener('input', () => {
    setFNameErr('');
  });
  lnameInput.addEventListener('input', () => {
    setLNameErr('');
  });

  emailInput.addEventListener('input', () => {
    setEmailErr('');
  });

  passwordInput.addEventListener('input', () => {
    setPasswordErr('');
  });

  // Validation checks
  var f = 1;

  if (fname.trim() === "") {
    setFNameErr("Please enter your first name");
    f = 0;
  }
  if (lname.trim() === "") {
    setLNameErr("Please enter your last name");
    f = 0;
  }

  if (email.trim() === "") {
    setEmailErr("Please enter your email address");
    f = 0;
  }
  if (email.trim() !== "" && !emailReg.test(email)) {
    setEmailErr("Please enter valid email");
    f = 0;
  }
  if (password.trim() === "") {
    setPasswordErr("Please enter your password");
    f = 0;
  }

  if (password.trim() !== "" && password.length < 6) {
    setPasswordErr("Password must be at least 6 characters long");
    f = 0;
  }
  
  if(f === 0){
    return false;
  }
  
  try {
    
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    
    // sending verfication email to user after signup.
    await sendEmailVerification(user);

    fnameInput.value = "";
    lnameInput.value = "";
    emailInput.value = "";
    passwordInput.value = "";

    // adding data to firestore
    await setDoc(doc(db, 'users', user.uid), {
      name: fname+" "+lname,
      email: email,
      uid: user.uid,
      image_url: 'https://imagemagix.co/images/user-default.png',
      provider: 'SignUp',
      device: 'desktop'
    });
    // adding data to api server. 
    const jsonObject = {};
    jsonObject["first_name"] = fname;
    jsonObject["last_name"] = lname;
    jsonObject["email"] = user.email;
    jsonObject["uuid"] = user.uid;
    jsonObject["image_url"] = "https://imagemagix.co/images/user-default.png";
    jsonObject["provider"] = "SignUp";
    jsonObject["device"] = "desktop";
    postData('/register/', jsonObject);
    setUser(user);
    setItem('userData', jsonObject);
    toast.success("Signup successful, Please check your inbox to verify the email.");
    
  } catch (error) {
    if (error.code === "auth/email-already-in-use") {
      toast.error("Email is already in use.");
    } else if(error.code === "auth/weak-password") {
      toast.error("Password you entered is week.");
    }else {
      toast.error("An error occurred while signing up. Please try again later.");
    }
  }
  
}

  return (
    <form
    >
      <div>
        <label for="name" className="form-label text-black">
          First Name
        </label>
        <input type="text" className="form-control shadow-none" id="fname" value={fname} onChange={()=>setFName()} />
        <div className="error-message text-danger">{fnameerr}</div>
      </div>
      <div>
        <label for="name" className="form-label text-black">
          Last Name
        </label>
        <input type="text" className="form-control shadow-none" id="lname" value={lname} onChange={()=>setLName()} />
        <div className="error-message text-danger">{lnameerr}</div>
      </div>
      <div className="mb-2">
        <label for="inputEmail1" className="form-label text-black">
          Email address
        </label>
        <input type="email" className="form-control shadow-none" id="email" value={email} onChange={()=>setEmail()} />
        <div className="error-message text-danger">{emailerr}</div>
      </div>
      <div className="mb-2">
        <label for="inputPassword1" className="form-label text-black">
          Password
        </label>
        <input type="password" className="form-control shadow-none" id="password" value={password} onChange={()=>setPassword()} />
        <div className="error-message text-danger">{passworderr}</div>
      </div>

      <button
        type="submit"
        className="mid-btn btn mt-2"
        style={{ width: "100%" }}
        onClick={(e)=>handleSignUp(e)}
      >
        Sign Up
      </button>
    </form>
  );
};

export default SignUp;
