import { API_URL, API_URL_AWS } from '../api/config';

export const postDataToAWS = async (data) => {
  // console.log(data);
    try {
      const url = API_URL_AWS;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type based on your API requirements
        },
        body: JSON.stringify(data), // Convert data to JSON string
        mode:'cors'
      });
  
    
      // if(response.status == "423") {
        const responseData = await response.json();
        // console.log(responseData);
        // console.log(response);
        // console.log(response.status);
      // }
      return responseData;
    } catch (error) {
      // console.error('Error in postData:', error);
      // throw error;
    }
  };
export const postData = async (endpoint, data) => {
  // console.log(data);
    try {
      const url = API_URL + endpoint;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type based on your API requirements
        },
        body: JSON.stringify(data), // Convert data to JSON string
        mode:'cors'
      });
  
    
      // if(response.status == "423") {
        const responseData = await response.json();
        // console.log(responseData);
        // console.log(response);
        // console.log(response.status);
      // }
      return responseData;
    } catch (error) {
      // console.error('Error in postData:', error);
      // throw error;
    }
  };
  
  export const postImage = async (endpoint, data) => {
    // console.log(data);
      try {
        const url = API_URL + endpoint;
        const response = await fetch(url, {
          method: 'POST',
          body: data, // Convert data to JSON string
          mode:'cors'
        });
    
        let responseData;
        if(response.status == "423") {
          responseData = await response.json();
        }else {
          responseData = await response.blob();
        }
        return responseData;
      } catch (error) {
        console.log(error);
        // console.error('Error in postData:', error);
        throw error;
      }
    };