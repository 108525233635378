

// import { initializeApp } from "firebase/app";
// import {getAuth} from 'firebase/auth'

// const firebaseConfig = {
//   apiKey: "AIzaSyB_tFhcVSURJiDzHna9p9owoBHNmFk7Lnk",
//   authDomain: "imageapp-47afb.firebaseapp.com",
//   databaseURL: "https://imageapp-47afb-default-rtdb.firebaseio.com",
//   projectId: "imageapp-47afb",
//   storageBucket: "imageapp-47afb.appspot.com",
//   messagingSenderId: "179746657388",
//   appId: "1:179746657388:web:f22067b19e122fb3e2b449"
// };


// const firebase = initializeApp(firebaseConfig);
// const auth =getAuth();

// export {firebase, auth}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA8xONaM72MszBJFeQTnoolEncbbEkAerY",
  authDomain: "imagemagix.firebaseapp.com",
  projectId: "imagemagix",
  storageBucket: "imagemagix.appspot.com",
  messagingSenderId: "164121167163",
  appId: "1:164121167163:web:901cdba237a0e62d5e231e",
  measurementId: "G-8W4TKCHQN8"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const auth =getAuth(firebase);
const db = getFirestore(firebase);
export {firebase, auth, db}
