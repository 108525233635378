import React from 'react'
import logo from '../logo7.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
<div className='footer-ipad mt-lg-3 mt-md-3 mt-3'>
        <div className='container footer-container mt-0'>
        <div className='row footer-row'>
        <div className='col-md-10'>
            <div className='footer-link'>
              <div className='footer-link2'>
              <Link to='/about' style={{textDecoration:'none',color:'#fff'}}>About Us</Link>
              <Link to='/privacy' style={{textDecoration:'none',color:'#fff'}}>Privacy Policy</Link>
              <Link to='/terms-condition' style={{textDecoration:'none',color:'#fff'}}>Terms & Conditions</Link>
              <Link to='/contact-us' style={{textDecoration:'none',color:'#fff'}}>Contact Us</Link>
              </div>
              <Link style={{textDecoration:'none'}} to='https://www.nextgentechedge.com'><span className='text-white'>Designed, Developed and Maintained by:</span> NextGen TechEdge Solutions Pvt. Ltd.</Link>
            </div>
        </div>
        <div className='col-md-2 my-auto'>
        <div className='footeer-img float-md-end'>
                <Link to='/'><img src={logo} alt="logo"/></Link>
            </div>
        </div>
        </div>
        </div>
  </div>

  )
}

export default Footer