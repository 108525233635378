// LoginForm.js
import { signInWithEmailAndPassword, GoogleAuthProvider } from '@firebase/auth';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { auth, db } from '../firebase';
import { UserContext } from '../context/usercontext';
import { toast } from 'react-toastify';
import { getDoc, doc } from 'firebase/firestore';
import { setItem } from '../storage/storage';
import { FaGoogle } from "react-icons/fa";


const SignIn = ({ toggleForms, handleForgotPasswordClick, handleModalHide }) => {

  const {setUser} = useContext(UserContext)

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailerr, setEmailErr] = useState('');
  const [passworderr, setPasswordErr] = useState('');

  const handleSignIn = async (e) => {
    e.preventDefault();

    const emailInput = document.getElementById("email");
    const passwordInput = document.getElementById("password");

    const email = emailInput.value;
    const password = passwordInput.value;
    const emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;


    emailInput.addEventListener('input', () => {
      setEmailErr('');
    });

    passwordInput.addEventListener('input', () => {
      setPasswordErr('');
    });

    // Validation checks
    var f = 1;

    if (email.trim() === "") {
      setEmailErr("Please enter your email address");
      f = 0;
    }
    if (email.trim() !== "" && !emailReg.test(email)) {
      setEmailErr("Please enter valid email");
      f = 0;
    }
    if (password.trim() === "") {
      setPasswordErr("Please enter your password");
      f = 0;
    }

    if (password.trim() !== "" && password.length < 6) {
      setPasswordErr("Password must be at least 6 characters long");
      f = 0;
    }

    if(f === 0){
      return false;
    }
    try {
      // Sign in the user with email and password
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // const user = userCredential.user;
      

      setUser(userCredential.user);
      const userDocRef = doc(db, 'users', userCredential.user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        setItem('userData', userDocSnap.data());
      }

      emailInput.value = "";
      passwordInput.value = "";

      // handleModalHide();
    } catch (error) {
      // console.log(error);
      if (error.code === "auth/invalid-login-credentials") {
        toast.error("Invalid credentials.");
      }else {
        toast.error("Some unexpected error while trying to sign in. Please try again.");
      }
    }
  };
  const handleGoogleSignIn = async () => {
    try {
      // Create a new instance of GoogleAuthProvider
      
      
    } catch (error) {
      console.error('Google sign-in error', error);
      toast('Google sign-in failed.');
    }
  };

  return (
    <form>
      <div className="mb-2">
        <label htmlFor="inputEmail1" className="form-label text-black">
          Email address
        </label>
        <input
          type="email"
          className="form-control shadow-none"
          id="email"
          value={email}
          onChange={()=>setEmail()}

        />
        <div className="error-message text-danger">{emailerr}</div>
        
      </div>
      <div className="mb-2">
        <label htmlFor="inputPassword1" className="form-label text-black">
          Password
        </label>
        <input
          type="password"
          className="form-control shadow-none"
          id="password"
          value={password}
          onChange={()=>setPassword()}

        />
        <div className="error-message text-danger">{passworderr}</div>
      </div>
      <Link className="float-end" onClick={handleForgotPasswordClick}>
        Forgot Password
      </Link>

      <button
        type="submit"
        className="mid-btn btn mt-2 mb-2"
        style={{ width: '100%' }}
        onClick={(e) => handleSignIn(e)}
      >
        Sign In
      </button>
      {/* <button
        type="button"
        className="btn text-white"
        style={{ width: "100%",background:'red' }}
        onClick={handleGoogleSignIn}
      >
      <FaGoogle /> Sign In With Google
      </button> */}
      <Link type="button" className="mt-2" onClick={toggleForms}>
        Don't have an account? Sign Up
      </Link>
    </form>
  );
};

export default SignIn;
