import '../App.css'
import { useFormik } from "formik";
import { contactSchema } from "../schemas";
import { useState,useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const initialValues = {
  name:'',
  email:'',
  subject:'',
  message:''
}

const Contact = () => {

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isCaptchaCompleted, setIsCaptchaCompleted] = useState(false);
  const recaptchaRef = useRef(null);

  // for capcha
  function onChange(value) {
    // console.log("Captcha value:", value);
    setIsCaptchaCompleted(true);
  }

  const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues:initialValues,
    validationSchema:contactSchema,
    onSubmit: async(values,{resetForm}) =>{
      if(isCaptchaCompleted){
      // console.log(values);
      const response = await fetch('https://api.imagemagix.co:34549/api/v1/contact-us/',
      {
        method:'POST',

        body:JSON.stringify({
          name:values.name,
          email:values.email,
          subject:values.subject,
          message:values.message
        })
      });

      if(response.ok){
        toast.success("Thanks for your query.");
        resetForm();
        setIsCaptchaCompleted(false);
        if (recaptchaRef.current) {
          recaptchaRef.current.reset(); // Reset the reCAPTCHA widget
        }
      }

    } else{
      toast.error("You must select re-captcha.");
    }
    }

  })


  return (
    <>
      <section className="container contact">
       {showSuccessAlert && (
        <div className="alert alert-success alert-dismissible fade show" role="alert">
          <strong>Hey,</strong> Thanks for your query.
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowSuccessAlert(false)}
            style={{border: 'none',
              borderRadius: '50%',
              height: '50px',
              width: '50px',
              color: '#ffffff',
              backgroundColor: 'red',
              marginLeft:'23rem'
            }}
          >
            <span aria-hidden="true">X</span>
          </button>
        </div>
    )}
        <h2 className="h1-responsive font-weight-bold text-center my-4">
          Contact us
        </h2>

        <p className="text-center mb-4">
          Do you have any questions? Please do not hesitate to contact us
          directly.<br/> Our team will come back to you within a matter of hours to
          help you.
        </p>
        <div className="row">

          <div className="col-md-12 mb-md-0 mb-5">
            <form className='contact-form' style={{marginInline:'5rem',padding:'2rem',borderRadius:'10px'}} onSubmit={handleSubmit}>

              <div className="row">

                <div className="col-md-6">
                  <div className="md-form mb-0">
                  <label htmlFor="name" className="">
                      Your name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      autoComplete="off"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? <p className="form-error text-danger">{errors.name}</p>:null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="md-form mb-0">
                  <label htmlFor="email" className="">
                    Your email
                  </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="form-control"
                      autoComplete="off"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ?<p className="form-error text-danger">{errors.email}</p>:null}
                  </div>
                </div>

              </div>

              <div className="row mt-lg-3 mt-0">
                <div className="col-md-12">
                  <div className="md-form mb-0">
                  <label htmlFor="subject" className="">
                      Subject
                    </label>
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      className="form-control"
                      autoComplete="off"
                      value={values.subject}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.subject && touched.subject ?<p className="form-error text-danger">{errors.subject}</p>:null}
                  </div>
                </div>
              </div>

              <div className="row mt-lg-3 mt-0">

                <div className="col-md-12">
                  <div className="md-form">
                  <label htmlFor="message">Your message</label>
                    <textarea
                      type="text"
                      id="message"
                      name="message"
                      rows={2}
                      className="form-control md-textarea"
                      autoComplete="off"
                      value={values.message}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.message && touched.message ?<p className="form-error text-danger">{errors.message}</p>:null}
                  </div>
                </div>
              </div>
              <div className="captcha">
                <ReCAPTCHA className='mt-3' ref={recaptchaRef} sitekey="6LcmtTspAAAAAArJWbCQ4dk9cnPDhIGaNdQwJGPq" onChange={onChange}/>
              </div>
              <div className='text-lg-start text-center'>
              <button type="submit" className="btn btn-primary rounded-4 mt-3 w-lg-0 w-50">Submit</button>
              </div>
            </form>
            <ToastContainer 
              position="top-center"
            />
          </div>
        </div>
      </section>

    </>
  );
};

export default Contact;
