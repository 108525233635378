import * as Yup from 'yup';

const scriptRegex = /<[^>]+>/;

export const contactSchema = Yup.object({
    name: Yup.string().min(2).max(20).test('name-validation', 'Invalid characters not allowed', value => !scriptRegex.test(value)).required('Please enter your name'),
    email: Yup.string().email().required('Please enter your email'),
    subject: Yup.string().min(10).max(30).test('subject-validation', 'Invalid characters not allowed', value => !scriptRegex.test(value)).required('Please enter your subject'),
    message: Yup.string().min(5).max(25).test('message-validation', 'Invalid characters not allowed', value => !scriptRegex.test(value)).required('Please enter your message'),
});
export const changePasswordSchema = Yup.object({
    newPassword: Yup.string().min(6).max(20).required('Please enter the new password'),
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match with the new password')
    .required('Please enter the confirm password'),
});

export const signUpSchema = Yup.object({
    name:Yup.string().min(2).max(20).required('Please enter your name'),
    email:Yup.string().email().required('Please enter your email'),
    password:Yup.string().min(6).max(20).required('Please enter your password'),
    
})

export const signInSchema = Yup.object({
    email:Yup.string().email().required('Please enter your email'),
    password:Yup.string().required('Please enter your password'),
    
})