import React, { useContext, useState } from "react";
import upscale from '../../images/upscale.png'
import upscale1 from '../../images/upscale1.jpg'
import blur from '../../images/blur.jpg'
import blur1 from '../../images/blur1.png'
import bgsvg from '../../images/bgsvg.svg'
import { Circles } from 'react-loader-spinner'

import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import { UserContext } from "../../context/usercontext";
import { toast } from "react-toastify";
import { postImage } from '../../api/api';
import { getItem } from '../../storage/storage';


const UpScale = () => {

  const [selectedImage, setSelectedImage] = useState(null);
  const [validationMessage, setValidationMessage] = useState('');
  const [loading, setLoading] = useState(false)
  const [imagesent, setImagesent] = useState(null);


  const { user } = useContext(UserContext);
  const userData = getItem("userData");
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setImagesent(selectedFile);
    setSelectedImage(URL.createObjectURL(selectedFile));
    setValidationMessage('');
  };

  const upScaleImage = async () => {
    if (!selectedImage) {
      setValidationMessage('Please choose a photo');
    } else {

      if (user.emailVerified) {
        setLoading(true)
        setSelectedImage(null)

        const formData = new FormData();
        formData.append('file', imagesent);

        const jsonData = JSON.stringify({
          "first_name": userData.name,
          "last_name": userData.name,
          "email": userData.email,
          "uuid": userData.uid,
          "image_url": userData.image_url,
          "device": userData.device,
          "provider": userData.provider,
        });

        formData.append('data', jsonData);

        try {
          const result = await postImage('/colorize-gray/', formData);
          if (typeof result === 'object' && result !== null && !(result instanceof Blob)) {
            if(result.status == "305") {
              setLoading(false);
              setSelectedImage(null);
              toast.error('Your today limit of 10 images reached, Please try tomorrow')    
            }
          } else {
            const NewImg = URL.createObjectURL(result);
            setSelectedImage(NewImg);
            setDownloadButtonVisible(true);
          }
        } catch (error) {
          toast.error('An error occurred while uploading the image. Please try again later.')
        } finally {
          setLoading(false)
        }
      } else {
        toast.error(
          <div>
            Please verify your email first from{' '}
            <a href="/profile">profile page</a>
          </div>,
          {
            html: true,
          }
        );
      }
      setValidationMessage('');
    }
  };

  const [downloadButtonVisible, setDownloadButtonVisible] = useState(false);

  const handleSaveImage = () => {
    const a = document.createElement('a');
    a.href = selectedImage;
    a.download = 'upscale.png';
    document.body.appendChild(a);

    a.click();
    document.body.removeChild(a);
    setSelectedImage(null);
    setDownloadButtonVisible(false);
  };


  return (
    <div className="container">
      <div className="row bg-reverse mt-lg-5 mt-3">
        <div className="col-lg-6 mt-lg-0 mt-5">
          <h1>Upscale Enlarge And Enhance Your Images</h1>
          <p>Embark on a journey of visual refinement with our cutting-edge technology. Our free AI photo enhancer delivers exceptional quality, allowing your photos to truly shine. Upscale, enlarge, and enhance your images with precision using our advanced editing tools.</p>
          <p> Elevate your visual storytelling as you unlock the full potential of your photographs, creating impactful and mesmerizing visuals that capture every detail. Experience the next level of image enhancement – try it now and witness the transformative power at your fingertips.</p>
        </div>
        <div className="col-lg-6 mt-2">
          <div style={{ width: "100%" }}>
            <ReactCompareSlider
              style={{ borderRadius: "10px" }}
              itemOne={
                <ReactCompareSliderImage
                  src={upscale1}
                  alt="Image one"
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src={upscale}
                  alt="Image two"
                />
              }
            />
          </div>
        </div>
      </div>

      <div className="row mt-lg-5 mt-3">
        <div className="col-lg-6 mt-2">
          <div style={{ width: "100%" }}>
            <ReactCompareSlider
              style={{ borderRadius: "10px" }}
              itemOne={
                <ReactCompareSliderImage
                  src={blur}
                  alt="Image one"
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src={blur1}
                  alt="Image two"
                />
              }
            />
          </div>
        </div>
        <div className="col-lg-6 mt-lg-0 mt-5">
          <h1 className='ps-lg-3'>Increase Resolution</h1>
          <p className='ps-lg-3'>
            Preserve the authenticity of your visuals with ImageMagix's state-of-the-art technology. Our AI image enhancer goes beyond mere resolution increase, ensuring a seamless enhancement process that retains the intricate details and textures of your photos.</p>
          <p className='ps-lg-3'> Whether you're working on digital art, photography, or any visual project, elevate your creations to a new level of clarity and precision. With ImageMagix, redefine your expectations for high-resolution images without compromising on the finer elements that make each photo unique. Explore the possibilities of enhanced resolution with ImageMagix's advanced AI technology today.
          </p>
        </div>
      </div>


      {/* upscale */}


      {user ? <div className="row d-flex flex-column align-items-center mt-5">
        <div className="col-lg-12 web-features">
          <div className="text-center">
            <h2
              className="features-heading"
              style={{ fontSize: "30px", fontWeight: "bold" }}
            >
              Upscale image
            </h2>
            <p>
              Effortlessly enhance the quality of your images with our cutting-edge upscaling solution.
            </p>
          </div>
          <div className="shadow p-2 mb-4 bg-body rounded">
            <div
              className="features-box d-flex flex-column align-items-center"
              style={{
                border: "5px dotted #c1c1c1",
                paddingBlock: "2rem",
                borderRadius: "5px",
                margin: "0.5rem",
              }}
            >
              {loading ? (
                <Circles
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                <div className="d-flex flex-column align-items-center">
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="selected_image"
                      style={{ width: "100%", padding: "1rem" }}
                    />
                  ) : (
                    <img src={bgsvg} alt="bg_svg" style={{ width: "100px" }} />
                  )}

                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />

                  {selectedImage ? null : (
                    <label
                      htmlFor="fileInput"
                      className="custom-file-input-button"
                      style={{
                        backgroundColor: "#010ea7",
                        padding: "0.5rem",
                        borderRadius: "5px",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      Choose a photo
                    </label>
                  )}

                  {selectedImage ? null : (
                    <p id="selectedFileName" style={{ textAlign: "center" }}>
                      No file selected
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          {selectedImage ? null : (
            <p id="selectedFileName" style={{ textAlign: "center" }}>
              {validationMessage}
            </p>
          )}

          <div className="text-center">
            {downloadButtonVisible ? (
              <button className="mid-btn" onClick={handleSaveImage}>
                Download Image
              </button>
            ) : (
              <button className="mid-btn" onClick={upScaleImage}>
                Upscale Image
              </button>

            )}
          </div>

        </div>
      </div> : null}
    </div>
  );
};

export default UpScale;
