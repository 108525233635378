import React, { useContext, useState } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";
import remove from '../../images/remove.jpg';
import remove1 from '../../images/remove1.png';
import remove2 from '../../images/remove2.jpg';
import remove3 from '../../images/remove3.png';
import bgsvg from '../../images/bgsvg.svg';
import { Circles } from 'react-loader-spinner'

import { UserContext } from '../../context/usercontext';
import { toast } from 'react-toastify';
import { postImage } from '../../api/api';
import { getItem } from '../../storage/storage';

const BackgroundRm = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [validationMessage, setValidationMessage] = useState('');
  const [loading, setLoading] = useState(false)
  const [imagesent, setImagesent] = useState(null);


  const { user } = useContext(UserContext);
  const userData = getItem("userData");
  console.log(userData);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setImagesent(selectedFile);
    setSelectedImage(URL.createObjectURL(selectedFile));
    setValidationMessage('');
  };

  const handleRemoveBackground = async () => {
    if (!selectedImage) {
      setValidationMessage('Please choose a photo');
    } else {

      if (user.emailVerified) {
        setLoading(true)
        setSelectedImage(null)

        const formData = new FormData();
        formData.append('file', imagesent);

        const jsonData = JSON.stringify({
          "first_name": userData.name,
          "last_name": userData.name,
          "email": userData.email,
          "uuid": userData.uid,
          "image_url": userData.image_url,
          "device": userData.device,
          "provider": userData.provider,
        });

        formData.append('data', jsonData);
        try {
          const result = await postImage('/remove-background/', formData);
          if (typeof result === 'object' && result !== null && !(result instanceof Blob)) {
            if(result.status == "305") {
              setLoading(false);
              setSelectedImage(null);
              toast.error('Your today limit of 10 images reached, Please try tomorrow')    
            }
          } else {
            const NewImg = URL.createObjectURL(result);
            setSelectedImage(NewImg);
            setDownloadButtonVisible(true);
          } 
        } catch (error) {
          toast.error('An error occurred while uploading the image. Please try again later.')
        } finally {
          setLoading(false)
        }
      } else {
        toast.error(
          <div>
            Please verify your email first from{' '}
            <a href="/profile">profile page</a>
          </div>,
          {
            html: true,
          }
        );
      }
      setValidationMessage('');
    }
  };

  const [downloadButtonVisible, setDownloadButtonVisible] = useState(false);

  const handleSaveImage = () => {
    const a = document.createElement('a');
    a.href = selectedImage;
    a.download = 'removed_background.png';
    document.body.appendChild(a);

    a.click();
    document.body.removeChild(a);
    setSelectedImage(null);
    setDownloadButtonVisible(false);
  };

  return (
    <div className="container">
      <div className="row mt-lg-5 mt-3 bg-reverse">
        <div className="col-lg-6 mt-lg-0 mt-5">
          <div>
            <h2 className="">
              ImageMagix BG Remover: Effortless Background Removal Powered by AI
            </h2>
            <p className="">
              Unshackle your images from unwanted backgrounds with ImageMagix BG
              Master – our AI-powered photo editing marvel. Say goodbye to
              tedious manual edits and embrace the future of visual creativity.{" "}
            </p>

            <p className="">
              Immerse yourself in a seamless experience as our advanced
              algorithms precisely and swiftly remove backgrounds, allowing you
              to focus on what truly matters – your stunning visuals.
            </p>
          </div>
        </div>
        <div className="col-lg-6 mt-2">
          <div
            className="featers"
            style={{
              width: "100%",
              border: "1px solid white",
              borderRadius: "10px",
            }}
          >
            <ReactCompareSlider
              style={{ borderRadius: "10px" }}
              itemOne={<ReactCompareSliderImage src={remove} alt="Image one" />}
              itemTwo={
                <ReactCompareSliderImage src={remove1} alt="Image two" />
              }
            />
          </div>
        </div>
      </div>

      <div className="row mt-lg-5 mt-3">
        <div className="col-lg-6 mt-2">
          <div
            className="featers"
            style={{
              width: "100%",
              border: "1px solid white",
              borderRadius: "10px",
            }}
          >
            <ReactCompareSlider
              style={{ borderRadius: "10px" }}
              itemOne={
                <ReactCompareSliderImage src={remove2} alt="Image one" />
              }
              itemTwo={
                <ReactCompareSliderImage src={remove3} alt="Image two" />
              }
            />
          </div>
        </div>
        <div className="col-lg-6 mt-lg-0 mt-5">
          <div className="bg-remover-content-2">
            <h2 className="mb-4 ps-lg-3">
              Backdrop Wizardry with ImageMagix: AI-Driven Background Removal at
              Your Fingertips
            </h2>
            <p className="ps-lg-3">
              ImageMagix brings you the ultimate tool for visual perfection –
              our AI-driven Background Remover. Bid farewell to distracting
              backgrounds as you effortlessly refine your images.{" "}
            </p>
            <p className="ps-lg-3">
              Our user-friendly app ensures that even complex edits are a
              breeze, granting you the power to craft captivating visuals with
              unmatched simplicity. Unleash your creativity with ImageMagix and
              let your images speak for themselves.
            </p>
          </div>
        </div>
      </div>

      {/*Remove Background*/}

      {user ?
        <div className="row d-flex flex-column align-items-center mt-5">
          <div className='col-lg-12 web-features'>
            <div className="text-center">
              <h2
                className="features-heading"
                style={{ fontSize: "30px", fontWeight: "bold" }}
              >
                Remove Background From Image
              </h2>
              <p>
                Get rid of any image background with our one-step background remover
                tool.
              </p>
            </div>
            <div className="shadow p-2 mb-4 bg-body rounded">
              <div
                className="features-box d-flex flex-column align-items-center"
                style={{
                  border: "5px dotted #c1c1c1",
                  paddingBlock: "2rem",
                  borderRadius: "5px",
                  margin: "0.5rem",
                }}
              >
                {loading ? (
                  <Circles
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  <div className="d-flex flex-column align-items-center">
                    {selectedImage ? (
                      <img
                        src={selectedImage}
                        alt="selected_image"
                        style={{ width: "100%", padding: "1rem" }}
                      />
                    ) : (
                      <img src={bgsvg} alt="bg_svg" style={{ width: "100px" }} />
                    )}

                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />

                    {selectedImage ? null : (
                      <label
                        htmlFor="fileInput"
                        className="custom-file-input-button"
                        style={{
                          backgroundColor: "#010ea7",
                          padding: "0.5rem",
                          borderRadius: "5px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        Choose a photo
                      </label>
                    )}

                    {selectedImage ? null : (
                      <p id="selectedFileName" style={{ textAlign: "center" }}>
                        No file selected
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
            {selectedImage ? null : (
              <p id="selectedFileName" className='text-danger' style={{ textAlign: "center" }}>
                {validationMessage}
              </p>
            )}



            <div className='text-center'>
              {downloadButtonVisible ? (

                <button className="mid-btn" onClick={handleSaveImage}>
                  Download Image
                </button>
              ) : (
                <button className="mid-btn" onClick={handleRemoveBackground}>
                  Remove Background
                </button>

              )}
            </div>
          </div>
        </div> : null}
    </div>
  );
}

export default BackgroundRm