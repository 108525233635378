import React from 'react'
import image3 from "../images/image3.png";
import image5 from "../images/img35.jpg";

const Middle = () => {
  return (
    <>
    <div className='container mt-5 middle-section'>
        <div className='row bg-reverse'>
            <div className='col-lg-8 mt-lg-0 mt-md-5 mt-5'>
            <div className='middle-section-detail-1 mb-5 mb-md-0'>
            <h2 className='mb-4'>Seamless Image Enhancement with ImageMagix's Photo Editor</h2>
            <p>ImageMagix's free photo editor offers a seamless solution for enhancing your images.
                 With our intuitive tools and features, you can effortlessly refine and elevate your
                  photos to the next level.</p><p> Whether you're a professional or just starting, our editor
                   provides a hassle-free experience to bring out the best in your images. Elevate your
                    photography game with our dynamic tools, ensuring that every detail shines in your images.</p>
                     <p> Try ImageMagix today and witness the ease of creating captivating visuals.</p>
            </div>
            </div>
            <div className='col-lg-4 mt-2'>
            <div>
                <img src={image3} alt='photo3' className='rounded-1' style={{width:'100%',border:'1px solid #e2e2e2'}} loading='lazy'/>
            </div>
            </div>
        </div>

        <div className='row mt-lg-5 mt-0'>
        <div className='col-lg-4 mt-2'>
            <div>
                <img src={image5} alt='photo3' className='rounded-1' style={{width:'100%',border:'1px solid #e2e2e2'}} loading='lazy'/>
            </div>
        </div>
            <div className='col-lg-8'>
                <div className='middle-section-detail-1 mt-lg-0 mt-md-5 mt-5'>
                    <h2 className='mb-4'>Elevate Your Workflow with Our AI-Backed Photo Editor</h2>
                    <p>Unleash the full potential of your images with our cutting-edge AI-powered tools. Effortlessly remove backgrounds,
                         infuse vibrant colors, and upscale your images to stunning clarity.</p>
                    <p>Our Background Remover feature ensures precision editing, while our Colorizing
                         tool brings new life to your visuals.</p>
                    <p>With AI-driven Image Upscaling, watch your images transform into high-resolution masterpieces,
                         capturing every detail with unparalleled precision. Elevate your editing process and experience
                          the transformative power of AI-enhanced image editing.</p>

                </div>
            </div>

        </div>
    </div>
    {/*<div className='middle-section-1 container d-flex align-items-center mt-5'>



    </div>

    <div className='middle-section-2 container d-flex align-items-center mt-5'>

    <div className='middle-section-detail-2' style={{paddingInline:'2rem'}}>
        <h2 style={{fontWeight:'500',color:'#413e3e'}}>Elevate Your Workflow with Our AI-Backed Photo Editor</h2>
        <p style={{fontWeight:'500',color:'#413e3e',fontSize:'14px'}}>Unleash the full potential of your images with our cutting-edge AI-powered tools.
        Effortlessly remove backgrounds, infuse vibrant colors, and upscale your images to stunning clarity.</p>
        <p style={{fontWeight:'500',color:'#413e3e',fontSize:'14px'}}>Our Background Remover feature ensures
         precision editing, while our Colorizing tool brings new life to your visuals.</p>
         <p style={{fontWeight:'500',color:'#413e3e',fontSize:'14px'}}>With AI-driven Image Upscaling, watch your images transform into high-resolution masterpieces, capturing every detail with unparalleled precision.
          Elevate your editing process and experience the transformative power of AI-enhanced image editing.</p>
        <button className='mid-btn'>Download app here</button>
    </div>
        <div>
            <img src={image5} alt='photo5' style={{width:'400px',height:'400px'}} loading='lazy'/>
        </div>

    </div>*/}

    </>
  )
}

export default Middle