import React, { useContext, useState } from 'react'
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";
import black from '../../images/black.jpg'
import black1 from '../../images/black 1.png'
import parent2 from '../../images/parent2.png'
import parent from '../../images/parent.jpg'
import bgsvg from '../../images/bgsvg.svg'
import { Circles } from 'react-loader-spinner'
import { UserContext } from '../../context/usercontext';
import { toast } from 'react-toastify';
import { postImage } from '../../api/api'

const Colorise = () => {

  const [selectedImage, setSelectedImage] = useState(null);
  const [validationMessage, setValidationMessage] = useState('');
  const [loading, setLoading] = useState(false)
  const [imagesent, setImagesent] = useState(null);


  const { user } = useContext(UserContext);
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setImagesent(selectedFile);
    setSelectedImage(URL.createObjectURL(selectedFile));
    setValidationMessage('');
  };

  const handleColoriseImage = async () => {
    if (!selectedImage) {
      setValidationMessage('Please choose a photo');
    } else {

      if (user.emailVerified) {
        setLoading(true)
        setSelectedImage(null)

        const formData = new FormData();
        formData.append('file', imagesent);

        const jsonData = JSON.stringify({
          "first_name": userData.name,
          "last_name": userData.name,
          "email": userData.email,
          "uuid": userData.uid,
          "image_url": userData.image_url,
          "device": userData.device,
          "provider": userData.provider,
        });

        formData.append('data', jsonData);
        try {
          const result = await postImage('/colorize-gray/', formData);
          if (typeof result === 'object' && result !== null && !(result instanceof Blob)) {
            if(result.status == "305") {
              setLoading(false);
              setSelectedImage(null);
              toast.error('Your today limit of 10 images reached, Please try tomorrow')    
            }
          } else {
            const NewImg = URL.createObjectURL(result);
            setSelectedImage(NewImg);
            setDownloadButtonVisible(true);
          }
        } catch (error) {
          toast.error('An error occurred while uploading the image. Please try again later.')
        } finally {
          setLoading(false)
        }
      } else {
        toast.error(
          <div>
            Please verify your email first from{' '}
            <a href="/profile">profile page</a>
          </div>,
          {
            html: true,
          }
        );
      }
      setValidationMessage('');
    }
  };

  const [downloadButtonVisible, setDownloadButtonVisible] = useState(false);

  const handleSaveImage = () => {
    const a = document.createElement('a');
    a.href = selectedImage;
    a.download = 'colorise.png';
    document.body.appendChild(a);

    a.click();
    document.body.removeChild(a);
    setSelectedImage(null);
    setDownloadButtonVisible(false);
  };


  return (
    <div className='container'>
      <div className='row bg-reverse mt-lg-5 mt-3'>
        <div className='col-lg-6 mt-lg-0 mt-5'>
          <div>
            <h2>Revive the Past with ImageMagix</h2>
            <p>Step into the captivating world of ImageMagix, where your cherished black and white memories are magically reborn in vibrant hues. Our cutting-edge app, renowned for its precision and accuracy, delicately preserves the authenticity of every element in your image.</p>
            <p> Immerse yourself in the nostalgia of yesteryears, as you rediscover the beauty of the past with a masterful touch of color that breathes new life into your timeless moments.</p>
          </div>
        </div>
        <div className='col-lg-6 mt-2'>
          <div className='colorise-image' style={{ width: "100%" }}>
            <ReactCompareSlider
              style={{ borderRadius: "10px" }}
              itemOne={
                <ReactCompareSliderImage
                  src={black1}
                  alt="Image one"
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src={black}
                  alt="Image two"
                />
              }
            />
          </div>
        </div>
      </div>


      <div className='row mt-lg-5 mt-3'>
        <div className='col-lg-6 mt-2'>
          <div className='colorise-image' style={{ width: "100%" }}>
            <ReactCompareSlider
              style={{ borderRadius: "10px" }}
              itemOne={
                <ReactCompareSliderImage
                  src={parent2}
                  alt="Image one"
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src={parent}
                  alt="Image two"
                />
              }
            />
          </div>
        </div>
        <div className='col-lg-6 mt-lg-0 mt-5'>
          <div>
            <h2 className='ps-lg-3'>Seamless Colorization, Unmatched Realism with ImageMagix</h2>
            <p className='ps-lg-3'>Immerse yourself in a world where black and white fades away, and vibrant realities emerge with the ImageMagix app. Experience seamless colorization with unmatched realism in every detail. With precision and accuracy, we breathe life into your images, preserving the authenticity of each cherished moment.</p>
            <p className='ps-lg-3'> Step into a realm where the past is reborn in vivid hues, creating a visual narrative that transcends time. Explore the art of colorization with ImageMagix – where memories come alive like never before.</p>

          </div>
        </div>
      </div>




      {/*colorise*/}

      {user ? <div className="row d-flex flex-column align-items-center mt-5">
        <div className='col-lg-12 web-features'>
          <div className="text-center">
            <h2
              className="features-heading"
              style={{ fontSize: "30px", fontWeight: "bold" }}
            >
              Image Colorizer | Restore your old family photos and bring them back to color
            </h2>
            <p>
              We are using the latest AI technology to restore old photos automatically and instantly
            </p>
          </div>
          <div className="shadow p-2 mb-4 bg-body rounded">
            <div
              className="features-box d-flex flex-column align-items-center"
              style={{
                border: "5px dotted #c1c1c1",
                paddingBlock: "2rem",
                borderRadius: "5px",
                margin: "0.5rem",
              }}
            >
              {loading ? (
                <Circles
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                <div className="d-flex flex-column align-items-center">
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="selected_image"
                      style={{ width: "100%", padding: "1rem" }}
                    />
                  ) : (
                    <img src={bgsvg} alt="bg_svg" style={{ width: "100px" }} />
                  )}

                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />

                  {selectedImage ? null : (
                    <label
                      htmlFor="fileInput"
                      className="custom-file-input-button"
                      style={{
                        backgroundColor: "#010ea7",
                        padding: "0.5rem",
                        borderRadius: "5px",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      Choose a photo
                    </label>
                  )}

                  {selectedImage ? null : (
                    <p id="selectedFileName" style={{ textAlign: "center" }}>
                      No file selected
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          {selectedImage ? null : (
            <p id="selectedFileName" style={{ textAlign: "center" }}>
              {validationMessage}
            </p>
          )}


          <div className='text-center'>
            {downloadButtonVisible ? (
              <button className="mid-btn" onClick={handleSaveImage}>
                Download Image
              </button>
            ) : (
              <button className="mid-btn" onClick={handleColoriseImage}>
                Colorise Image
              </button>

            )}
          </div>


        </div>

      </div> : null}


    </div>
  )
}

export default Colorise