import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Forgot from './Forgot'; // Import your ForgotPasswordForm component
import SignUp from './SignUp';
import SignIn from './SignIn';

const AccountSignInUp = () => {
  const [showModal, setShowModal] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleClose = () => setShowModal(false);
  
  const handleLoginClick = () => {
    setShowModal(true);
    setShowLogin(true);
    setShowForgotPassword(false); // Reset forgot password state
  };

  const handleForgotPasswordClick = () => {
    setShowModal(true);
    setShowLogin(false);
    setShowForgotPassword(true); // Show forgot password form
  };

  const toggleForms = () => {
    setShowLogin(!showLogin);
    setShowForgotPassword(false); // Hide forgot password when toggling between login/signup
  };
  const handleModalHide = () => {
    setShowModal(false);
  }
  return (
    <>
      <button className='btn text-white' onClick={handleLoginClick}>
        Sign in
      </button>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-black'>
            {showLogin ? 'Sign in' : showForgotPassword ? 'Forgot Password' : 'Sign Up'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showLogin && <SignIn handleForgotPasswordClick={handleForgotPasswordClick} toggleForms={toggleForms} />}
          {!showLogin && !showForgotPassword && <SignUp/>}
          {showForgotPassword &&  <Forgot handleModalHide={handleModalHide}/>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AccountSignInUp;
