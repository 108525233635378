import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy-terms" style={{ height: "50vh" }}>
        <div
          className="web-content-heading d-flex fw-bold text-white"
          style={{
            paddingTop: "8rem",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1 className="text-center">Privacy Policy of ImageMagix</h1>
          <Link to='/' className="btn btn-primary px-3 rounded-4" style={{textDecoration:'none'}}>More About ImageMagix</Link>
        </div>
      </div>

      <div className="container">
      <div className="web-content" style={{marginInline:'5rem',fontSize:'14px',lineHeight:'2rem'}}>
        <div className="mt-5">
          <h4 style={{fontWeight:'bold'}}>Privacy Policy for ImageMagix</h4>
          <p>
            This Privacy Policy describes how NextGen TechEdge Solutions Pvt. Ltd. ("we," "us," or
            "our") collects, uses, and shares information in connection with
            your use of the ImageMagix (the "App").{" "}
          </p>
        </div>

        <div>
          <h4>Information We Collect </h4>
         
            <p>
              <span style={{fontWeight:'bold',fontSize:'20px'}}>Personal Information: </span>We do collect any personal
              information that can directly identify individuals, such as names,
              email addresses.
            </p>
            <p>
              <span style={{fontWeight:'bold',fontSize:'20px'}}>Device Information: </span>We may collect certain information
              about your device, including the type of device, operating system,
              unique device identifiers, and mobile network information.
            </p>
            <p>
              <span style={{fontWeight:'bold',fontSize:'20px'}}>Usage Information: </span>We may collect information about
              how you use the App, including your access times, browsing
              activities, and interaction with certain features.
            </p>
            <p>
              <span style={{fontWeight:'bold',fontSize:'20px'}}>Photo Information: </span>The App may require access to your
              photos to enable editing functionalities. We do not store or
              access your photos unless explicitly authorized by you.
            </p>
          
        </div>

        <div>
          <h4>How We Use Your Information </h4>
          
            <p>
              We may use the information collected to provide, maintain, and
              improve the App and to develop new features and functionalities.{" "}
            </p>
            <p>
              The collected information may also be used to analyze trends,
              administer the App, and gather demographic information for
              internal use.{" "}
            </p>
            <p>
              We do not use your information for marketing or advertising
              purposes without your explicit consent.{" "}
            </p>
        
        </div>

        <div>
          <h4>Information Sharing and Disclosure </h4>
          
            <p>
              We do not sell, trade, or otherwise transfer your information to
              outside parties.{" "}
            </p>
            <p>
              We may share your information with trusted third-party service
              providers who assist us in operating the App and conducting our
              business, as long as these parties agree to keep the information
              confidential.{" "}
            </p>
            <p>
              We may also disclose your information when required to comply with
              legal obligations, enforce our policies, or protect our or others'
              rights, property, or safety.{" "}
            </p>
          
        </div>

        <div>
          <h4>Data Security </h4>
          <p>
            We implement a variety of security measures to maintain the safety
            of your information when you use the App.{" "}
          </p>
        </div>

        <div>
          <h4>Changes to This Privacy Policy </h4>
          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time. Any changes will be effective immediately upon the posting of
            the revised Privacy Policy.{" "}
          </p>
        </div>

        <div>
          <h4>Contact Us </h4>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us.
          </p>
        </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
