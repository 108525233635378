import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <div>

    <div className="privacy-terms" style={{ height: "50vh" }}>
        <div
          className="web-content-heading d-flex fw-bold text-white"
          style={{
            paddingTop: "8rem",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1 className='text-center'>About Us of ImageMagix</h1>
          <Link to='/' className="btn btn-primary px-3 rounded-4" style={{textDecoration:'none'}}>More About ImageMagix</Link>
        </div>
      </div>

      <div className='container mt-5'>
        <div className='web-content' style={{marginInline:'5rem'}}>
            <h2 style={{fontWeight:'bold'}}>About ImageMagix Mobile App</h2>
            <p style={{lineHeight:'2rem',fontSize:'14px'}}>Welcome to the ImageMagix Mobile App, your on-the-go solution for all your photo editing needs. Our app is designed to empower users to unleash their creativity anytime, anywhere, right from the convenience of their mobile devices.</p>
            <p style={{lineHeight:'2rem',fontSize:'14px'}}>At ImageMagix, we believe that everyone has a story to tell through their images, and our mission is to make the process of creating and enhancing these stories as seamless and enjoyable as possible. Our user-friendly interface and powerful editing tools are tailored to cater to all levels of expertise, from beginners to seasoned professionals, enabling you to bring your vision to life with just a few taps.</p>
            <p style={{lineHeight:'2rem',fontSize:'14px'}}>With a wide array of filters, effects, and editing features, ImageMagix Mobile App provides you with the tools you need to transform your photos into stunning works of art. Whether you're looking to make quick adjustments or dive deep into advanced editing techniques, our app has you covered.</p>
            <p style={{lineHeight:'2rem',fontSize:'14px'}}>We are committed to staying at the forefront of mobile photo editing technology, continuously updating and improving our app to provide you with the best possible experience. Our team is dedicated to ensuring that every moment spent using the ImageMagix Mobile App is a moment dedicated to creating something extraordinary.</p>
            <p style={{lineHeight:'2rem',fontSize:'14px'}}>Join our community of passionate creators and photographers, and embark on a journey of visual storytelling and creative exploration. ImageMagix Mobile App is more than just an editing tool; it's your partner in capturing and sharing moments that matter.</p>
            <p style={{lineHeight:'2rem',fontSize:'14px'}}>Thank you for choosing the ImageMagix Mobile App. Let's turn your vision into reality.</p>
        </div>

    
        </div>
    </div>
  )
}

export default About