// storage.js

// Get an item from session storage
export const getItem = (key) => {
    try {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error('Error getting item from sessionStorage:', error);
      return null;
    }
  };
  
  // Set an item in session storage
  export const setItem = (key, value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error setting item in sessionStorage:', error);
    }
  };
  
  // Remove an item from session storage
  export const removeItem = (key) => {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing item from sessionStorage:', error);
    }
  };
  